import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { Notify, Button } from 'zent';
import Style, { PriceStyle } from './style';
import { buriedPoint } from 'utils/log-buried-point';
import DefaultImg from '../default-img';
import LocalStorage from '../../../util/local-storage';
import Toast from 'cpn/toast';
import { WISH_COUNT_CHANGE } from '../../../util/local-storage/config';
import shopApi from '../../../api/shop';
import { useRootContext } from 'client/pages/context/root';
import BuyNow from '../bay-now';
import SkuStyle from './sku';
import isEmpty from 'lodash/isEmpty';
import { HtGoodsListPrice, HtSkuPrice } from 'cpn/goods-price';
import { TagList } from 'components/page/goods/tag-list';
import { TRACK } from 'const';
import cn from 'classnames';
import { GOODS_FEATURE_TYPE } from '../../../const';

const GoodsWrapper = (props) => {
  const { item, onEnter, currency, initData = {}, language, showWish = true, imageRatio, extra = {} } = props;
  // eslint-disable-next-line no-undef
  const { userInfo = global_data?.userInfo || {}, themeData = {}, kdtId } = initData;
  const { title = '- ', minPrice, media = [], inventory = 0, compareAtPrice, bestVariant = {}, variants = [] } = item;
  const intl = useIntl();
  const { setLoginDialogInfo } = useRootContext();
  const [clientWidth, setClientWidth] = useState(500);
  const [wishStatus, setWishStatus] = useState(false);
  const [wishActionLoading, setWishActionLoading] = useState(false);
  const [wishId, setWishId] = useState('');
  const { current = {} } = themeData;
  const skuSelectRef = useRef({});
  const [skuBlockStatus, setSkuBlockStatus] = useState(false);
  const [isMultipleSku, setIsMultipleSku] = useState(false);
  // 是否直接立即购买  否则就是显示添加到购物车
  const [enablePaymentButton, setEnablePaymentButton] = useState(false);
  // sku page 组件是否需要
  const [skuPageShow, setSkuPageShow] = useState(false);
  // sku page 组件是否可用
  const [skuPageCompleteStatus, setSkuPageCompleteStatus] = useState(false);
  // H5 赠品页显示 加购和立即购买
  const [visibleGiftAction, setVisibleGiftAction] = useState(false);

  const triggerSkuBlock = (status = false) => {
    setSkuBlockStatus(status);
    setSkuPageShow(false);
  };

  const setGoodsMultipleSkuStatus = (hasSkuOptions) => {
    setIsMultipleSku(hasSkuOptions);
  };

  const showSkuOrAction = (event, buyShowStatus) => {
    if (event) event.stopPropagation();
    setEnablePaymentButton(buyShowStatus);
    setSkuPageShow(true);
    if (!skuPageCompleteStatus) return;
    if (isMultipleSku) {
      setSkuBlockStatus(true);
    }
    if (!isMultipleSku && buyShowStatus) {
      skuSelectRef.current.buyNow();
    }
    if (!isMultipleSku && !buyShowStatus) {
      skuSelectRef.current.addCar(null, extra); // 第一个是event
      setSkuPageShow(false);
    }
    setVisibleGiftAction(false);
  };

  useEffect(() => {
    if (skuPageCompleteStatus) {
      showSkuOrAction(false, enablePaymentButton);
    }
  }, [skuPageCompleteStatus]);

  const SkuPage = () => {
    const isPC = window && document.body.clientWidth > 750;
    const containerDisplay = isPC ? 'block' : 'flex';
    const skuElement = (
      <SkuStyle>
        <div
          style={{
            display: skuBlockStatus ? containerDisplay : 'none',
          }}
          className='add-shopping-car-container'
          onClick={(e) => e.stopPropagation()}
        >
          <BuyNow
            skuSelectRef={skuSelectRef}
            setGoodsMultipleSkuStatus={setGoodsMultipleSkuStatus}
            goodsId={item?.goodsId}
            kdtId={kdtId}
            triggerSkuBlock={triggerSkuBlock}
            enablePaymentButton={enablePaymentButton}
            setSkuPageCompleteStatus={setSkuPageCompleteStatus}
            currency={currency}
            extra={extra}
          />
        </div>
      </SkuStyle>
    );
    if (isPC) {
      return skuElement;
    }
    return createPortal(skuElement, document.getElementById('root'));
  };

  useEffect(() => {
    const getClientWidth = () => {
      if (document) {
        setClientWidth(document.body.clientWidth);
      }
    };
    if (userInfo && userInfo?.customerId) {
      setWishStatus(item?.wishList?.isWishList === 1);
      setWishId(item?.wishList?.wishListId);
    } else {
      setWishStatus(false);
    }
    getClientWidth();
    if (window) window.addEventListener('resize', getClientWidth);
    return () => {
      if (window) window.removeEventListener('resize', getClientWidth);
    };
  }, [item]);

  const coverUrl = useMemo(() => {
    if (item?.url) {
      return item?.url;
    }
    const mainMedia = media[0];
    if (!mainMedia) return '';
    if (mainMedia?.type === 'VIDEO') {
      return mainMedia?.coverUrl;
    }
    return mainMedia?.url;
  }, [media]);

  /** 心愿操作 添加到心愿单或者从心愿单删除 */
  const wishAction = (e) => {
    e.stopPropagation();
    if (wishActionLoading) return;
    setWishActionLoading(true);
    const wishChangeCount = LocalStorage.get(WISH_COUNT_CHANGE) || 0;
    const customerId = userInfo?.customerId;
    const goodsId = item?.goodsId;
    if (!customerId) {
      setLoginDialogInfo({
        visible: true,
      });
      setWishActionLoading(false);
      return;
    }
    if (wishStatus) {
      const params = { customerId, wishListId: wishId };
      shopApi
        .deleteWishGoods(params)
        .then(() => {
          Toast.info(
            intl.formatMessage({
              defaultMessage: '已取消收藏',
              id: 'has.cancel.in_wish',
            }),
            800,
          );
          setWishStatus(false);
          LocalStorage.set(WISH_COUNT_CHANGE, wishChangeCount - 1);
        })
        .catch((res) => {
          Notify.error(res.message);
        })
        .finally(() => {
          setWishActionLoading(false);
        });
    } else {
      const params = { customerId, goodsId, kdtId };
      shopApi
        .addToWishList(params)
        .then((res) => {
          setWishId(res?.wishListId);
          setWishStatus(true);
          Notify.success(
            intl.formatMessage({
              defaultMessage: '已添加至心愿单',
              id: 'has.add.to_wish',
            }),
            1000,
          );
          buriedPoint(
            'product_add_to_wishlist',
            {
              source: '',
              ...item,
            },
            {
              name: '加心愿单',
              eventType: 'click',
            },
          );
          LocalStorage.set(WISH_COUNT_CHANGE, wishChangeCount + 1);
        })
        .catch((res) => {
          Notify.error(res.message);
        })
        .finally(() => {
          setWishActionLoading(false);
        });
    }
  };

  const heightPercent = useMemo(() => {
    if (imageRatio) {
      // 兼容商品分组3.0旧数据
      if (imageRatio.includes('%')) {
        return imageRatio;
      }
      if (imageRatio !== 'auto' && imageRatio !== 'adapt') {
        const arr =
          imageRatio.indexOf('-') !== -1 ? imageRatio.split('-').map(Number) : imageRatio.split('/').map(Number);
        return `${(arr[1] / arr[0]) * 100}%`;
      }
    }
    return '100%';
  }, [imageRatio]);

  const onClickGoodsItem = () => {
    if (extra?.giftGoodsFlag == 1 && clientWidth <= 768) {
      setVisibleGiftAction(true);
    } else {
      onEnter && onEnter();
    }
  };

  // 是否为服务商品
  const isServiceGoods = item.goodsFeatureType === GOODS_FEATURE_TYPE.newService;
  const isECardGoods = item.goodsFeatureType === GOODS_FEATURE_TYPE.eCard;

  return (
    <Style className='goods-item' onClick={onClickGoodsItem} heightPercent={heightPercent}>
      <div className='goods-item-wrapper'>
        {skuPageShow && <SkuPage />}
        <div className={!inventory ? 'goods-item-image sold-out-image' : 'goods-item-image'}>
          <div
            className={cn('goods-item-image-inner', {
              'customer-ratio': imageRatio && imageRatio !== 'auto' && imageRatio !== 'adapt',
            })}
          >
            {coverUrl ? (
              <img className='goods-img' src={coverUrl} alt={title} />
            ) : (
              <DefaultImg customName='goods-img' defaultHeight />
            )}
          </div>
          {!inventory && (
            <div className='sold-out'>
              <FormattedMessage id='goods.sold_out' />
            </div>
          )}
          {!!inventory && !isServiceGoods && !isECardGoods && (
            <div className={cn('float-action', { 'visible-action': visibleGiftAction })}>
              <Button
                className={`action-add theme-sub  ${TRACK.AddCartButton}`}
                onClick={(e) => {
                  e.stopPropagation();
                  showSkuOrAction(e, false);
                  buriedPoint('category_buynow');
                }}
              >
                {intl.formatMessage({ defaultMessage: '加入购物车', id: 'goods.cart.add' })}
              </Button>
              <Button
                type='primary'
                className={`theme-primary action-buy ${TRACK.BuyOnceButton}`}
                onClick={(e) => {
                  e.stopPropagation();
                  showSkuOrAction(e, true);
                  buriedPoint('category_checkout');
                }}
              >
                {intl.formatMessage({ defaultMessage: '立即购买', id: 'goods.buy_once' })}
              </Button>
            </div>
          )}
        </div>
        <div>
          <h3 className='title'>{title}</h3>
          <div className='price-and-wish-wrapper'>
            <PriceStyle className='price'>
              {isEmpty(bestVariant) ? (
                <HtSkuPrice
                  price={minPrice}
                  compareAtPrice={compareAtPrice}
                  discountInfo={item?.discountInfo}
                  currency={currency}
                />
              ) : (
                <HtGoodsListPrice bestVariant={bestVariant} variants={variants} />
              )}
            </PriceStyle>
            {showWish && !isServiceGoods && !isECardGoods && (
              <div
                className='wish-wrapper'
                onClick={(e) => {
                  wishAction(e);
                }}
              >
                {wishStatus ? (
                  <svg className='icon goods-list-icon' aria-hidden='true' fill={current.color_button || '#F54F29'}>
                    <use xlinkHref='#iconic-shoucang1' />
                  </svg>
                ) : (
                  <svg className='icon goods-list-icon' aria-hidden='true' fill='var(--color_body_text)'>
                    <use xlinkHref='#iconic-shoucang2' />
                  </svg>
                )}
              </div>
            )}
          </div>
          {/* 折扣标识 */}
          <TagList
            discountInfo={item?.discountInfo}
            groupActivityDescription={item?.activityDescriptionInfo?.groupActivityDescription}
            language={language}
          />
        </div>
      </div>
    </Style>
  );
};

export default withRouter(GoodsWrapper);
