export enum MainTypeEnum {
  product = 0, // 产品
  productCategory = 1, // 商品分组
  pages = 2, // 自定义页面
  link = 3, // 链接
  allProducts = 4, // 所有产品
  homePage = 5, // 主页
  searchPage = 6, // 搜索页面
  cartPage = 7, // 购物车页面
  orderPage = 8, // 订单
  wishListPage = 9, // 心愿单
  mePage = 10, // 我的
  collectPage = 11, // 收藏
  allCategoryPage = 12, // 所有分类
  policyShippingPolicy = 13, // 物流政策
  policyReturnPolicy = 14, // 退换政策
  policySecurePayment = 15, // 安全保障
  policyTermsOfUse = 16, // 用户协议
  policyPrivacyPolicy = 17, //  隐私政策
  policyContactUs = 18, // 联系我们
  blogHome = 19, // 博客主页
  luckDraw = 20, // 抽奖
  groupBuyingPage = 21, // 拼团分组页
  minProgramPath = 22, // 小程序路径
  formPage = 23, // 自定义表单页
  cardCode = 24, // 一卡一码落地页
  blogDetailPage = 26, // 单个blog页面
  distributorHomePage = 27, // 分销员主页
  paidMemberShipHomePage = 28, // 付费会员主页
  appointmentPage = 29, // 服务页面
  points = 30, // 积分
  rule = 31, // 规则
  blog = 32, // 博客
  orderInStorePickupPage = 33, // 下单-到点自提
  orderMerchantDeliveryPage = 34, // 下单-商家配送
  phone = 35, // 拨打电话
  productRuleCategory = 36, // 产品类
}

/**
 * 全局公用的获取跳转链接
 * type 页面类型
 * data 扩展数据
 * 之所以写函数,是因为获取链接后期可能有逻辑判断，而不是简单的枚举
 */
export const getLinkUrl = (link) => {
  const linkInfo = link ? JSON.parse(link) : {};
  const isExistType = Object.keys(linkInfo)?.includes('urlType'); // 旧数据不存在这个东西
  const type = linkInfo?.urlType;
  const oldUrl = linkInfo?.url ?? {}; // 旧地址
  const handle = linkInfo?.handle;
  if (!link) return '';

  if (!isExistType) {
    return linkInfo?.url;
  } else {
    const URL_MAP = {
      [MainTypeEnum.product]: handle ? `/products/${handle}` : oldUrl, // 商品详情
      [MainTypeEnum.productCategory]: handle ? `/collections/${handle}` : oldUrl, // 商品分组
      [MainTypeEnum.homePage]: '/', // 主页
      [MainTypeEnum.pages]: handle ? `/pages/${handle}` : oldUrl, // 自定义页
      [MainTypeEnum.allProducts]: '/collections/all',
      [MainTypeEnum.searchPage]: '/search',
      // [MainTypeEnum.cartPage]: '/pages/cart/index',
      // [MainTypeEnum.orderPage]: '/pages/orders/list/index',
      // [MainTypeEnum.wishListPage]: '/pages/wish/index',
      // [MainTypeEnum.mePage]: '/pages/me/home/index',
      // [MainTypeEnum.collectPage]: '/pages/goods/collection/index',
      // [MainTypeEnum.categoryPage]: '/pages/goods/group/index',
      [MainTypeEnum.groupBuyingPage]: '/group-buying/list',
      [MainTypeEnum.blogHome]: '/blogs', // 博客
      // [MainTypeEnum.luckDraw]: '/pages/activity/index',
      // [MainTypeEnum.settings]: '/pages/settings/index',
      // [MainTypeEnum.formPage]: '/pages2/form/index',
      [MainTypeEnum.policyShippingPolicy]: '/policy/Shipping-Policy', // 物流政策
      [MainTypeEnum.policyReturnPolicy]: '/policy/Return-Policy', // 退换政策
      [MainTypeEnum.policySecurePayment]: '/policy/Secure-Payment', // 安全政策
      [MainTypeEnum.policyPrivacyPolicy]: '/policy/Privacy-Policy', // 隐私政策
      [MainTypeEnum.policyTermsOfUse]: '/policy/Terms-Of-Use', // 用户协议
      [MainTypeEnum.policyContactUs]: '/policy/Contact-Us', // 联系我们
    };

    return URL_MAP[type];
  }
};
